var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "bg": ['#F2F2F2', 'white'],
      "h": "100%",
      "min-h": ['calc(100vh - 64px)', '100%'],
      "pb": "8px"
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumbPath
    }
  })], 1), _c('c-box', {
    attrs: {
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '12px'],
      "w": "100%",
      "px": ['8px', '85px'],
      "pt": ['8px', '0'],
      "pb": ['0', '30px']
    }
  }, [_c('c-flex', {
    attrs: {
      "display": ['none', 'flex'],
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "padding-block": "30px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Nilai Gizi ")])], 1), _c('c-box', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "padding-inline": ['0px', '20px'],
      "padding-block": ['12px', '0px'],
      "background-color": "#FFF"
    }
  }, [_c('GlossaryContainer', {
    attrs: {
      "route-name": "client.glossaries-detail",
      "breakpoint": _vm.currentBreakpoint
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }